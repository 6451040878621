import axios from '@/utils/axios';
const baseURL = '/api'

// 获取民族
export const getNationList = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/dict/dictionary/?code=nation_code',
    method: 'get',
    params
  });
};

// 获取民族
export const getNationList2 = (params) => {
  return axios({
    baseURL,
    url: '/convenient/votersregister/appList',
    method: 'get',
    params
  });
};

// 获取性别
export const getSexList = (params) => {
  return axios({
    baseURL,
    url: '/blade-system/dict/dictionary?code=sex',
    method: 'get',
    params
  });
};

// 新增
export const save = (data) => {
  return axios({
    baseURL,
    url: '/convenient/votersregister/save',
    method: 'post',
    data
  });
};

// 修改
export const update = (data) => {
  return axios({
    baseURL,
    url: '/convenient/votersregister/update',
    method: 'post',
    data
  })
}

// 详情
export const detail = (params) => {
  return axios({
    baseURL,
    url: '/convenient/votersregister/detail',
    method: 'get',
    params
  });
};

// 撤销
export const revocation = (data) => {
  return axios({
    baseURL,
    url: '/convenient/votersregister/revocation',
    method: 'post',
    data
  });
};