<template>
  <div class="record-list">
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getRegistrationList"
      >
        <div class="item" v-for="item in list" :key="item.id" @click="goDetail(item.id)">
          <div class="left">
            <div class="name row">姓名: {{ item.realName }}</div>
            <div class="time row">登记时间: {{ item.createTime }}</div>
          </div>
          <div class="right">
            <span class="success" v-if="item.checkRes == 1">{{ checkRes(item.checkRes) }}</span>
            <span class="wait" v-else>{{ checkRes(item.checkRes) }}</span>
          </div>
        </div>
        
      </van-list>
    </div>
  </div>
</template>

<script>
import { getNationList2 } from '@/api/registrationApi'

export default {
  name: 'RegistrationList',
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      currentList: -1,
    }
  },
  created() {
    // this.getRegistrationList()
  },
  computed: {
    checkRes() {
      return status => {
        switch (status) {
          case 0:
            return '审核中'
          case 1:
            return '审核成功'
          case 2:
            return '审核失败'
          default:
            return '审核中'
        }
      }
    },
  },
  methods: {
    // 获取记录列表
    async getRegistrationList() {
      const res = await getNationList2({
        openId: JSON.parse(localStorage.getItem('yt-openId')).content,
      })

      if (res.code == 200) {
        this.loading = false
        this.finished = true; 
        this.list = res.data.records
      }
    },
    // 跳转详情页
    goDetail(id) {
      this.$router.push('/registrationDetail?id=' + id)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
